import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Chart, AxisOptions } from 'react-charts';
import clsx from 'clsx';

import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/FlowRouter';
import { PaymentPageResult } from 'enums/StudentLoanForgivenessFlowResults';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import AreYouSureModal from 'components/StudentLoanForgiveness/Modal/AreYouSureModal';
import { ReactComponent as ProfileIcon } from 'images/profile.svg';
import { ReactComponent as LockIcon } from 'images/lock.svg';
import { ReactComponent as MoneyIcon } from 'images/money-purple.svg';
import { ReactComponent as Contract } from 'images/contract.svg';
import { addMonths } from 'date-fns';
import Expander from 'components/Expander/Expander';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import {
  setupStudentLoanPayment,
  getStudentLoanApplication as getStudentLoanApplicationThunk,
  getStudentLoanAssistanceData as getStudentLoanAssistanceDataThunk,
} from 'thunks';
import { RoutePath } from 'enums/Routes';
import { getStudentLoanApplication } from 'selectors/getStudentLoanApplication';
import { getPartnerDataFromEmployerName } from 'enums/PartnerName';
import { StudentLoanAssistancePaymentPlan, StudentLoanAssistancePaymentStatus } from 'handlers/applicationData';
import Checkbox from 'components/Checkbox';
import { getApplicationData } from 'selectors/getApplicationData';
import Loader from 'components/Loader';

import ToggleButtonGroup, { ToggleButton } from './components/ToggleButtonGroup';

import styles from './Submit.module.scss';

enum PaymentOption {
  Card3Installments = 'Card3Installments',
  Card12Installments = 'Card12Installments',
  PayrollDeduction = 'Payroll Deduction',
}

// This is used to estimate savings over time. Each element in the array is expected to be the payment per month.
const ADVISOR_COST = {
  [PaymentOption.Card3Installments]: Array(3).fill(330),
  [PaymentOption.Card12Installments]: Array(6).fill(165),
  [PaymentOption.PayrollDeduction]: Array(6).fill(165),
};

const Submit = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const [areYouSureModalOpen, setAreYouSureModalOpen] = useState(false);
  const [isSetupPaymentStarted, setIsSetupPaymentStarted] = useState(false);
  
  const { applicationId, currentPath, recommendedPath, isLoading: isLoadingStudentLoanAssistanceData } = useSelector(
    getStudentLoanData,
  );
  const { application } = useSelector(getApplicationData);

  const {
    applicationData: { employerName },
  } = useSelector(getStudentLoanApplication);

  // The following code ensures that the application is loaded before advancing to the next step.
  useEffect(() => {
    applicationId &&
      (async () => {
        await Promise.all([
          dispatchWithUnwrap(getStudentLoanApplicationThunk({ applicationId })),
          dispatchWithUnwrap(getStudentLoanAssistanceDataThunk({ applicationId })),
        ]);
      })();
  }, []);

  const supportsPayrollDeduction = getPartnerDataFromEmployerName(employerName)
    ?.supportsStudentLoanApplicationPayrollDeduction;

  const supportsCard12Installments = true;

  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const [paymentOption, setPaymentOption] = useState<PaymentOption | null>(
    supportsPayrollDeduction ? null : PaymentOption.Card3Installments,
  );

  let defaultPaymentOption = PaymentOption.Card3Installments;
  if (supportsPayrollDeduction) defaultPaymentOption = PaymentOption.PayrollDeduction;
  if (supportsCard12Installments) defaultPaymentOption = PaymentOption.Card12Installments;

  const advisorCost = ADVISOR_COST[paymentOption ?? defaultPaymentOption].reduce((total, value) => total + value, 0);
  const currentPathCost = currentPath?.totalPayment ?? 0;
  const recommendedPathCost = (recommendedPath?.totalPayment ?? 0) + advisorCost;
  const savings = currentPathCost - recommendedPathCost;
  const [checked, setChecked] = useState(false);

  const startDate = new Date();

  const chartData = React.useMemo(
    () =>
      getChartData(paymentOption ?? defaultPaymentOption, startDate, currentPath?.payments, recommendedPath?.payments),
    [paymentOption, isLoadingStudentLoanAssistanceData],
  );
  const primaryAxis = React.useMemo(
    (): AxisOptions<DataPoint> => ({
      getValue: (datum) => datum.date,
      hardMin: startDate,
      min: startDate,
      padBandRange: false,
      formatters: {
        cursor: (value: Date) =>
          value &&
          value.toLocaleDateString('en-US', {
            month: 'short',
            year: 'numeric',
          }),
        tooltip: (value: Date) =>
          value &&
          value.toLocaleDateString('en-US', {
            month: 'short',
            year: 'numeric',
          }),
      },
    }),
    [],
  );
  const secondaryAxes = React.useMemo(
    (): AxisOptions<DataPoint>[] => [
      {
        getValue: (datum) => datum.amount,
        formatters: {
          scale: (value: number) => formatMonetaryAmount(value),
        },
      },
    ],
    [],
  );

  const onNext = async () => {
    analytics.track('Student Loan Setup Payment Continue', { paymentOption });
    setAreYouSureModalOpen(false);
    if (!isSetupPaymentStarted) {
      setIsSetupPaymentStarted(true);

      switch (paymentOption) {
        case PaymentOption.Card3Installments:
          window.location.replace(
            (
              await dispatchWithUnwrap(
                setupStudentLoanPayment({
                  paymentPlan: StudentLoanAssistancePaymentPlan.Card3Payments,
                  applicationId: applicationId!,
                  returnUrl: `${window.location.origin}${RoutePath.StudentLoanApplyPaymentResult}${window.location.search}`,
                }),
              )
            ).checkoutUrl,
          );
          break;
        case PaymentOption.Card12Installments:
          window.location.replace(
            (
              await dispatchWithUnwrap(
                setupStudentLoanPayment({
                  paymentPlan: StudentLoanAssistancePaymentPlan.Card12Payments,
                  applicationId: applicationId!,
                  returnUrl: `${window.location.origin}${RoutePath.StudentLoanApplyPaymentResult}${window.location.search}`,
                }),
              )
            ).checkoutUrl,
          );
          break;
        case PaymentOption.PayrollDeduction:
          await dispatchWithUnwrap(
            setupStudentLoanPayment({
              paymentPlan: StudentLoanAssistancePaymentPlan.PayrollDeduction12Payments,
              applicationId: applicationId!,
              returnUrl: `${window.location.origin}${RoutePath.StudentLoanApplyPaymentResult}${window.location.search}`,
            }),
          );
          handleNext(PaymentPageResult.PayrollDeduction);
          break;
        default:
          setIsSetupPaymentStarted(false);
          handleNext();
      }
    }
  };

  const handleNotNow = () => {
    analytics.track('Student Loan Setup Payment Not Now Pressed');
    handleNext(PaymentPageResult.NotNow);
  };

  const paymentOptions: ToggleButton<string>[] = [
    {
      value: PaymentOption.Card3Installments,
      title: (
        <div className={styles.title}>
          Pay with Card<div className={styles.value}>$330 due today</div>
        </div>
      ),
      subtitle: '3 interest-free monthly payments of $330',
    },
  ];

  if (supportsCard12Installments) {
    paymentOptions.push({
      value: PaymentOption.Card12Installments,
      title: (
        <div className={styles.title}>
          Pay with Card<div className={styles.value}>$82.50 due today</div>
        </div>
      ),
      subtitle: '12 interest-free biweekly payments of $82.50',
    });
  } else {
    paymentOptions.push({
      value: PaymentOption.PayrollDeduction,
      title: (
        <div className={styles.title}>
          Payroll Deduction<div className={styles.value}>$0 due today</div>
        </div>
      ),
      subtitle: '12 interest-free biweekly payments of $82.50',
      children: (
        <p className={styles.description}>
          {supportsPayrollDeduction && (
            <>
              This will be set up after you complete your application. If you are not enrolled, your payroll deduction
              will be cancelled and any amount charged will be refunded.
            </>
          )}
          {!supportsPayrollDeduction && (
            <>
              Sorry, we don't yet support {employerName ?? 'your employer'}. If you'd like us to include them, please
              contact us.
            </>
          )}
        </p>
      ),
      expandOnSelect: true,
    });
  }

  useEffect(() => {
    if (application?.studentLoanAssistancePaymentState?.status === StudentLoanAssistancePaymentStatus.Active) {
      handleNext(PaymentPageResult.AlreadyPaid);
    }
  }, []);
  
  return (
    <>
      {areYouSureModalOpen && (
        <AreYouSureModal
          onClose={() => setAreYouSureModalOpen(false)}
          onNext={() => setAreYouSureModalOpen(false)}
          onNotNow={handleNotNow}
          nextLabel="Continue"
        />
      )}
      <FormNavigation {...navigationInfo} />
      <FormContainer
        icon={<Contract />}
        title="Apply with Us"
        subtitle="We charge a one time fee that pays for our advisors to work on your application and ensure your approval."
      >
        <div className={styles.advantages}>
          <div className={styles.advantage}>
            <ProfileIcon className={styles.icon} />
            <p className={styles.advantageLabel}>
              This fee covers all of the work to consolidate your loans, select and apply for the assistance programs,
              appeal decisions, and any coordination necessary with you and {employerName ?? 'your employer'}.
            </p>
          </div>
          <div className={styles.advantage}>
            <LockIcon className={styles.icon} />
            <p className={styles.advantageLabel}>
              We guarantee enrollment in the plans you qualify for, or we will refund the full amount.
            </p>
          </div>
          <div className={styles.advantage}>
            <MoneyIcon className={styles.icon} />
            <p className={styles.advantageLabel}>We recommended this option as the best way to achieve your goals.</p>
          </div>
        </div>

        {isLoadingStudentLoanAssistanceData && (
          <div className={styles.loader}>
            <Loader color="#9d86f9" size={90} />
          </div>
        )}
        {savings > 0 && !isLoadingStudentLoanAssistanceData && (
          <Expander
            label={
              <div className={styles.row}>
                <div className={styles.label}>Estimated Net Savings:</div>
                <div className={styles.value}>{formatMonetaryAmount(savings)}</div>
              </div>
            }
            content={
              <div className={styles.savingsDetail}>
                <div className={styles.legend}>
                  <div className={clsx(styles.item, styles.highlighted)}>
                    <div className={styles.bullet} />
                    <div className={styles.content}>
                      <div className={styles.title}>Updated Path including fees</div>
                      <div className={styles.value}>({formatMonetaryAmount(recommendedPathCost)} Total Payment)</div>
                    </div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.bullet} />
                    <div className={styles.content}>
                      <div className={styles.title}>Original Path</div>
                      <div className={styles.value}>({formatMonetaryAmount(currentPathCost)} Total Payment)</div>
                    </div>
                  </div>
                </div>
                <div className={styles.chartWrapper}>
                  <Chart
                    options={{
                      data: chartData,
                      primaryAxis,
                      secondaryAxes,
                      defaultColors: ['#795AF7' /* purple500 */, '#C5CBD4' /* black20 */],
                    }}
                  />
                </div>
              </div>
            }
            labelClassname={styles.expanderTitle}
            container={styles.expanderContainer}
            openClassname={styles.expanderOpen}
          />
        )}

        <div className={styles.paymentOptions}>
          <p className={styles.paymentOptionHeading}>Choose a payment option:</p>
          <ToggleButtonGroup
            defaultValue={paymentOption ?? undefined}
            onChange={(value) => {
              analytics.track('Student Loan Setup Payment Option Click', { value });
              setPaymentOption(value as PaymentOption);
            }}
            options={paymentOptions}
          />
        </div>

        <Checkbox
          label="I understand that the actual savings may differ from Plannery’s estimate."
          checked={checked}
          onChange={() => setChecked(!checked)}
        />

        <div className={styles.buttonContainer}>
          <Button
            onClick={onNext}
            isLoading={isSetupPaymentStarted}
            disabled={
              !checked ||
              !paymentOption ||
              (paymentOption === PaymentOption.PayrollDeduction && !supportsPayrollDeduction)
            }
          >
            Continue
          </Button>

          <Button type={ButtonType.Transparent} onClick={() => setAreYouSureModalOpen(true)}>
            Not Now
          </Button>
        </div>
      </FormContainer>
    </>
  );
};

interface DataPoint {
  date: Date;
  amount: number;
}

const getChartData = (
  paymentOption: PaymentOption,
  startDate: Date,
  currentPathPayments?: number[],
  recommendedPathPayments?: number[],
): { label: string; data: DataPoint[] }[] => {
  const currentPayments = [...(currentPathPayments ?? [])];
  const recommendedPayments = [...(recommendedPathPayments ?? [])];

  ADVISOR_COST[paymentOption].forEach((amount, index) => {
    if (index === recommendedPayments.length) {
      recommendedPayments.push(0);
    }
    recommendedPayments[index] += amount;
  });

  while (recommendedPayments.length < currentPayments.length) {
    recommendedPayments.push(0);
  }

  return [
    {
      label: 'Recommended Path',
      data: convertToCumulativeSeries(startDate, recommendedPayments),
    },
    {
      label: 'Current Path',
      data: convertToCumulativeSeries(startDate, currentPayments),
    },
  ];
};

const convertToCumulativeSeries = (startDate: Date, payments: number[]): DataPoint[] => {
  let totalPayment = 0;
  return payments.map((payment, index) => {
    totalPayment += payment;
    return { date: addMonths(startDate, index), amount: totalPayment };
  });
};

export default Submit;
