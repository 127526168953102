import React, { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactLoading from 'react-loading';
import { RootState } from 'handlers';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import DropzoneWidget, { useFiles } from 'components/DropzoneWidget';
import { getApplicationData } from 'selectors/getApplicationData';
import { StepComponent } from 'components/Steps/stepUtils';
import { getStudentLoanApplication } from 'selectors/getStudentLoanApplication';
import { StepsResult } from 'enums/FlowNextResults';
import { ReactComponent as CheckIcon } from 'images/green-check-rounded.svg';
import { ReactComponent as InboxImage } from 'images/inbox.svg';
import {
  getStudentLoanApplication as getStudentLoanApplicationThunk,
  uploadEnrollmentForm,
  sendStudentLoanEnrollmentForm,
} from 'thunks';

import styles from './SignEnrollmentForm.module.scss';

const FilesPreview = React.lazy(() => import('components/FilesPreview'));

const renameFiles = (cb: (files: File[]) => void, filePrefix?: string) => (files: File[]) => {
  const prefix = filePrefix ? `${filePrefix} ` : '';
  return cb(files.map((file) => new File([file], `${prefix}${file.name}`, { type: file.type })));
};

const SignEnrollmentForm = ({ handleNext }: StepComponent) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { handleAddFiles, files, handleRemoveFiles } = useFiles();

  const {
    applicationId,
    applicationData: { applyProgress },
  } = useSelector(getStudentLoanApplication);

  const { isApplicationStatusUpdating } = useSelector(getApplicationData);
  const { isLoading } = useSelector((state: RootState) => state.filesUpload);

  // The following code ensures that the application is loaded before advancing to the next step.
  useEffect(() => {
    applicationId &&
      (async () => {
        await dispatchWithUnwrap(getStudentLoanApplicationThunk({ applicationId }));
      })();
  }, []);

  useEffect(() => {
    if (applicationId) {
      dispatchWithUnwrap(sendStudentLoanEnrollmentForm(applicationId));
    }
  }, [applicationId]);

  const handleContinue = async () => {
    const filesForUpload: File[] = files.map((item) => item.file);

    analytics.track('Upload Ennrollment Form in Progress');
    await dispatchWithUnwrap(
      uploadEnrollmentForm({
        applicationId: applicationId!,
        file: filesForUpload[0],
      }),
    );

    handleNext(StepsResult.Completed);
  };

  return (
    <FormContainer
      title="Sign Enrollment Form"
      subtitle="Check your email for your form. For the form to be accepted, follow these steps."
      icon={<InboxImage />}
      className={styles.formContainer}
    >
      <ol>
        <li>Print the form attached to the email.</li>
        <li>
          Complete and sign page 1 <b>in pen.</b> <b>Do not</b> check the box "I believe I qualify for forgiveness
          now..."
        </li>
        <li>
          <b>Scan as a PDF</b> and upload the packet here. We'll take care of the rest.
        </li>
      </ol>
      <p>Upload the file below:</p>

      <DropzoneWidget
        handleFiles={renameFiles(handleAddFiles, 'EnrollmentForm')}
        extensions={['application/pdf']}
        multiple={false}
      />

      {files.length ? (
        <Suspense fallback={<ReactLoading type="spin" color="#fff" />}>
          <FilesPreview files={files} handleRemove={handleRemoveFiles} />
        </Suspense>
      ) : (
        <></>
      )}

      {applyProgress?.enrollmentForm && (
        <>
          <div className={styles.uploaded}>
            <CheckIcon className={styles.checkIcon} />
            <p className={styles.verifiedLabel}>Enrollment form uploaded</p>
          </div>

          {!files.length && <Button onClick={() => handleNext(StepsResult.Completed)}>Next</Button>}
        </>
      )}

      <Button
        disabled={!files.length}
        className={styles.button}
        onClick={handleContinue}
        isLoading={isLoading || isApplicationStatusUpdating}
      >
        Submit Authorization
      </Button>
    </FormContainer>
  );
};

export default SignEnrollmentForm;
